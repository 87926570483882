<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import CultureChart from "@/components/CultureChart.vue";
import * as apiSumary from "@/api/stats/sumaryVotes";
export default {
	components: {
		CultureChart,
	},
	data() {
		return {
			listed: false,
			topTerritories: [],
			lastTerritories: [],
			topRegions: [],
			lastRegions: [],
			topAgencies: [],
			lastAgencies: [],
			topUsers: [],
			filters: {
				period_id: {
					name: "Periodo",
					options: {},
					selected: null,
				},
				territory_id: {
					name: "Territorio",
					options: {},
					selected: null,
				},
				region_id: {
					name: "Región",
					options: {},
					selected: null,
				},
			},
			fetchFilters: {},
			sumary: {
				emitted_votes: 0,
				emitter_users: 0,
				received_votes: 0,
				receiver_users: 0,
			},
			fields: {
				name: { name: "Nombre" },
				work_position: { name: "Puesto" },
				received_votes: { name: "Votos" },
			},
		};
	},
	created() {
		this.setLoading(false);
		this.loadFilter("period_id");
		this.loadFilter("territory_id");
		this.loadTopTerritories();
		this.loadLastTerritories();
		this.loadTopUsers({ size: "15" });
		this.loadSumary();
	},
	computed: {
		...mapGetters({
			token: "loginStore/readToken",
			isLoading: "loading/isLoading",
			//READ_TOP
			readTopTerritories: "getTopTerritories/readTop",
			readTopRegions: "getTopRegions/readTop",
			readTopAgencies: "getTopAgencies/readTop",
			readTopUsers: "getTopUsers/readTop",

			//READ_FILTERS
			readPeriods: "getPeriods/readPeriods",
			readTerritories: "getTerritories/readTerritories",
			readRegions: "regionsStore/readRegions",

			//READ_ERRORS
			getPeriodsError: "getPeriods/readError",
			territoriesError: "getTerritories/readError",
			regionsError: "regionsStore/readError",
			topTerritoriesError: "getTopTerritories/readError",
			topUsersError: "getTopUsers/readError",
		}),
		topFiveUsers() {
			return this.topUsers.slice(0, 5);
		},
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",

			//TOP_FILTERS
			setTopTerritoriesSort: "getTopTerritories/SET_SORT",
			setTopTerritoriesFilter: "getTopTerritories/SET_FILTER",
			setTopTerritoriesFilters: "getTopTerritories/SET_FILTERS",
			setTopRegionsFilters: "getTopRegions/SET_FILTERS",
			setTopAgenciesFilters: "getTopAgencies/SET_FILTERS",
			setTopUsersSort: "getTopUsers/SET_SORT",
			setTopUsersFilter: "getTopUsers/SET_FILTER",
			setTopUsersFilters: "getTopUsers/SET_FILTERS",

			//SET_FILTERS_ID
			setRegionId: "regionsStore/SET_ID",
			setRegionFilter: "regionsStore/SET_FILTER",
			setAgencyId: "agenciesStore/SET_ID",
			setAgencyFilter: "agenciesStore/SET_FILTER",

			//SET_ERRORS
			setPeriodsError: "getPeriods/SET_ERROR",
			setTerritoriesError: "getTerritories/SET_ERROR",
			setRegionsError: "regionsStore/SET_ERROR",
			setTopTerritoriesError: "getTopTerritories/SET_ERROR",
			setTopUsersError: "getTopUsers/SET_ERROR",
		}),
		...mapActions({
			//TOP
			getTopTerritories: "getTopTerritories/getTop",
			getTopRegions: "getTopRegions/getTop",
			getTopAgencies: "getTopAgencies/getTop",
			getTopUsers: "getTopUsers/getTop",
			//GET_FILTERS
			getPeriods: "getPeriods/getPeriods",
			getTerritories: "getTerritories/getTerritories",
			regionActions: "regionsStore/regionActions",
		}),
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		loadSumary(filters = {}) {
			this.setLoading(true);
			apiSumary
				.getSumary(this.token, filters)
				.then((response) => {
					if (response) {
						this.$set(this, "sumary", response);
					}
				})
				.finally(() => {
					this.setLoading(false);
				});
		},
		loadTopTerritories(filters = {}) {
			this.setLoading(true);
			this.setTopTerritoriesFilters(filters);
			this.getTopTerritories()
				.then((response) => {
					if (response) {
						if (this.readTopTerritories.length > 0) {
							this.topTerritories = this.readTopTerritories;
						}
					}
				})
				.finally(() => {
					this.setLoading(false);
				});
		},
		loadLastTerritories(filters = {}) {
			this.setLoading(true);

			filters.sort_by = "received_votes";
			filters.sort = "ASC";

			// this.setTopTerritoriesfilters("received_votes");
			// this.setTopTerritoriesSort("ASC");
			this.setTopTerritoriesFilters(filters);
			this.getTopTerritories()
				.then((response) => {
					if (response) {
						if (this.readTopTerritories.length > 0) {
							this.lastTerritories = this.readTopTerritories;
						}
					}
				})
				.finally(() => {
					this.setLoading(false);
				});
		},
		loadTopRegions(filters = {}) {
			this.setLoading(true);
			this.setTopRegionsFilters(filters);
			this.getTopRegions()
				.then((response) => {
					if (response) {
						if (this.readTopRegions.length > 0) {
							this.topRegions = this.readTopRegions;
						}
					}
				})
				.finally(() => {
					this.setLoading(false);
				});
		},
		loadLastRegions(filters = {}) {
			this.setLoading(true);
			filters.sort_by = "received_votes";
			filters.sort = "ASC";

			// this.setTopTerritoriesfilters("received_votes");
			// this.setTopTerritoriesSort("ASC");
			this.setTopRegionsFilters(filters);
			this.getTopRegions()
				.then((response) => {
					if (response) {
						if (this.readTopRegions.length > 0) {
							this.lastRegions = this.readTopRegions;
						}
					}
				})
				.finally(() => {
					this.setLoading(false);
				});
		},
		loadTopAgencies(filters = {}) {
			this.setLoading(true);
			this.setTopAgenciesFilters(filters);
			this.getTopAgencies()
				.then((response) => {
					if (response) {
						if (this.readTopAgencies.length > 0) {
							this.topAgencies = this.readTopAgencies;
						}
					}
				})
				.finally(() => {
					this.setLoading(false);
				});
		},
		loadLastAgencies(filters = {}) {
			this.setLoading(true);
			filters.sort_by = "received_votes";
			filters.sort = "ASC";

			// this.setTopTerritoriesfilters("received_votes");
			// this.setTopTerritoriesSort("ASC");
			this.setTopAgenciesFilters(filters);
			this.getTopAgencies()
				.then((response) => {
					if (response) {
						if (this.readTopAgencies.length > 0) {
							this.lastAgencies = this.readTopAgencies;
						}
					}
				})
				.finally(() => {
					this.setLoading(false);
				});
		},
		loadTopUsers(filters = {}) {
			// this.setTopUsersFilter(null);
			// this.setTopUsersSort(null);
			this.setTopUsersFilters(filters);
			this.getTopUsers().then((response) => {
				if (response) {
					if (this.readTopUsers.length > 0) {
						this.topUsers = this.readTopUsers;
					}
				}
			});
		},
		loadFilter(filter) {
			this.listed = false;
			this.setLoading(true);
			this.clearErrors();
			this.clearFilter("period_id");
			this.clearFilter("territory_id");
			this.clearFilter("region_id");
			if (filter == "period_id") {
				this.getPeriods().then((response) => {
					if (response) {
						if (this.readPeriods.length > 0) {
							for (let p in this.readPeriods) {
								this.filters.period_id.options[
									this.readPeriods[p].id
								] = this.readPeriods[p].name;
							}
						}
					}
				});
			} else if (filter == "territory_id") {
				this.getTerritories().then((response) => {
					if (response) {
						if (this.readTerritories.length > 0) {
							for (let t in this.readTerritories) {
								this.filters.territory_id.options[
									this.readTerritories[t].id
								] = this.readTerritories[t].name;
							}
						}
					}
				});
			} else {
				this.regionActions("getRegions").then((response) => {
					if (response) {
						if (this.readRegions.length > 0) {
							for (let r in this.readRegions) {
								this.filters.region_id.options[
									this.readRegions[r].id
								] = this.readRegions[r].name;
							}
						}
					}
				});
			}
			this.setLoading(false);
		},
		refreshFilter(selected) {
			this.setLoading(true);
			this.clearErrors();
			this.listed = false;
			this.users = [];

			this.exporting = false;
			this.exported = false;

			if (selected == "territory_id") {
				this.clearFilter("region_id");
				// this.clearFilter("agency_id");

				//REGIONS
				this.setRegionId(null);
				this.setRegionFilter(this.filters.territory_id.selected);
				this.regionActions("getRegions")
					.then((response) => {
						if (response) {
							if (this.readRegions.length > 0) {
								for (let r in this.readRegions) {
									this.filters.region_id.options[
										this.readRegions[r].id
									] = this.readRegions[r].name;
								}
							}
						}
					})
					.finally(() => {
						this.setLoading(false);
						this.loadStats();
					});
			} else {
				this.setLoading(false);
				this.loadStats();
			}
		},
		loadStats() {
			let filters = {};
			for (const property in this.filters) {
				// console.log(`${property}: ${object[property]}`);
				let selected = this.filters[property].selected;
				if (selected) {
					filters[property] = selected;
				}
			}
			if (filters.hasOwnProperty("region_id")) {
				this.loadTopAgencies({ ...filters });
				this.loadLastAgencies({ ...filters });
			} else if (filters.hasOwnProperty("territory_id")) {
				this.loadTopRegions({ ...filters });
				this.loadLastRegions({ ...filters });
			} else {
				this.loadTopTerritories({ ...filters });
				this.loadLastTerritories({ ...filters });
			}
			this.loadTopUsers({ ...filters, size: "15" });
			this.loadSumary({ ...filters });
			// this.$set(this, 'fetchFilters', filters)
		},
		getSummary() {},
		clearFilter(filter) {
			this.filters[filter].selected = null;
			this.filters[filter].options = {};
		},
		clearErrors() {
			this.setPeriodsError(null);
			this.setTopTerritoriesError(null);
		},
	},
};
</script>

<template>
	<div class="metricas main">
		<form v-if="true" class="bg-gray shadow-sm">
			<div
				class="container m-auto flex justify-evenly md:justify-between items-center flex-wrap px-3 md:px-6 py-8 lg:py-10"
			>
				<h1 class="w-full text-center b text-xl mb-6">
					Metricas filtradas por:
				</h1>
				<label
					class="w-full md:w-1/4 md:mr-6 mt-3 md:mt-0"
					:for="f.name"
					v-for="(f, key) in filters"
					:key="f.name"
				>
					<select
						@change="refreshFilter(key)"
						class="w-full px-4 py-2 text-lg bg-white rounded-md h-12"
						:name="f.name"
						:id="f.name"
						v-model="f.selected"
					>
						<option :value="null">Seleccionar {{ f.name }}</option>
						<option :value="null">Global</option>
						<option v-for="(o, i) in f.options" :key="i" :value="i">
							{{ o }}
						</option>
					</select>
				</label>
				<div class="w-full">
					<!-- Errors -->
				</div>
			</div>
		</form>
		<div v-if="!isLoading" class="container m-auto">
			<div class="xl:grid grid-cols-2 grid-graphic">
				<div class="summary">
					<h2 class="w-full text-3xl py-6 px-2">Resumen</h2>
					<div class="flex flex-wrap items-center mt-1">
						<div class="stat w-full md:w-1/2 p-2 text-center">
							<div class="bg-gray rounded">
								<p class="stat__count text-3xl py-6 red b">
									{{ sumary.received_votes }}
								</p>
								<p class="stat__name blue text-lg pb-3">
									Reconocimientos
									<span class="b">recibidos</span>
								</p>
							</div>
						</div>
						<div class="stat w-full md:w-1/2 p-2 text-center">
							<div class="bg-gray rounded">
								<p class="stat__count text-3xl py-6 red b">
									{{ sumary.emitted_votes }}
								</p>
								<p class="stat__name blue text-lg pb-3">
									Reconocimientos
									<span class="b">enviados</span>
								</p>
							</div>
						</div>
						<div class="stat w-full md:w-1/2 p-2 text-center">
							<div class="bg-gray rounded">
								<p class="stat__count text-3xl py-6 red b">
									{{ sumary.receiver_users }}
								</p>
								<p class="stat__name blue text-lg pb-3">
									Colaboradores
									<span class="b">reconocidos</span>
								</p>
							</div>
						</div>
						<div class="stat w-full md:w-1/2 p-2 text-center">
							<div class="bg-gray rounded">
								<p class="stat__count text-3xl py-6 red b">
									{{ sumary.emitter_users }}
								</p>
								<p class="stat__name blue text-lg pb-3">
									Colaboradores
									<span class="b">reconociendo</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				<table
					id="no-more-tables"
					class="block lg:table table-auto w-full mt-24 mx-2 row-span-2"
				>
					<thead class="block lg:table-header-group bg-green-dark">
						<tr class="block lg:table-row border border-gray">
							<th
								class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
							>
								No.
							</th>
							<th
								class="block text-white lg:table-cell b border-r border-gray py-3 px-1"
								v-for="(field, key) in fields"
								:key="key"
							>
								{{ field.name }}
							</th>
						</tr>
					</thead>
					<tbody class="block md:flex flex-wrap lg:table-row-group">
						<tr
							class="block odd:bg-gray-light hover:bg-orange lg:table-row border-t border-b border-gray box-border"
							:class="
								topUsers.length > 1
									? 'w-full md:w-1/2 lg:w-full'
									: 'w-full'
							"
							v-for="(u, i) in topUsers"
							:key="i"
						>
							<td
								class="block before:text-center before:justify-center first:border-l lg:table-cell text-center border-r border-gray-light py-3 px-1"
							>
								{{ i + 1 }}
							</td>
							<td
								class="block before:text-center before:justify-center first:border-l lg:table-cell border-r border-gray-light py-3 px-1"
								:class="
									key == 'name'
										? 'text-left pl-2'
										: 'text-center'
								"
								v-for="(field, key) in fields"
								:key="key"
								v-capitalizeWords="u[key]"
							></td>
						</tr>
					</tbody>
				</table>
				<div class="w-full lg:px-2 mb-10">
					<CultureChart :filters="filters" />
				</div>

			</div>
			<h2 class="w-full text-3xl py-6">
				Top <span class="b red">5</span> de la semana
			</h2>
			<div v-if="filters.region_id.selected">
				<p class="blue text-lg pb-3">
					Puntos de venta con
					<span class="b">mayor</span> reconocimiento:
				</p>
				<div
					class="flex justify-center flex-wrap pb-6 stats-wrap md:justify-between"
				>
					<div
						class="w-60 h-46 p-2"
						v-for="t in topAgencies"
						:key="t.id"
					>
						<div
							class="card flex-none h-full gradient-green flex flex-col justify-end items-center rounded-lg p-3 text-white"
						>
							<p class="b mb-auto" v-capitalizeWords="t.name"></p>
							<div class="w-full">
								<hr class="bg-yellow h-0.5 my-2 w-full block" />
								<p class="flex justify-between mb-2">
									Recibidos:
									<span
										class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
										>{{ t.received_votes }}</span
									>
								</p>
								<p class="flex justify-between">
									Enviados:<span
										class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
										>{{ t.emitted_votes }}</span
									>
								</p>
							</div>
						</div>
					</div>
				</div>
				<p class="blue text-lg pb-3">
					Puntos de venta con
					<span class="b">menor</span> reconocimiento:
				</p>
				<div
					class="flex justify-center flex-wrap pb-6 stats-wrap md:justify-between"
				>
					<div
						class="p-2 h-46 w-60"
						v-for="t in lastAgencies"
						:key="t.id"
					>
						<div
							class="card flex-none gradient-red flex flex-col justify-end items-center h-full rounded-lg p-3 text-white"
						>
							<p class="b mb-auto" v-capitalizeWords="t.name"></p>
							<div class="w-full">
								<hr class="bg-yellow h-0.5 my-2 w-full block" />
								<p class="flex justify-between mb-2">
									Recibidos:
									<span
										class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
										>{{ t.received_votes }}</span
									>
								</p>
								<p class="flex justify-between">
									Enviados:<span
										class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
										>{{ t.emitted_votes }}</span
									>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else-if="filters.territory_id.selected">
				<p class="blue text-lg pb-3">
					Regiones con <span class="b">mayor</span> reconocimiento:
				</p>
				<div
					class="flex justify-center flex-wrap pb-6 stats-wrap md:justify-between"
				>
					<div
						class="w-60 h-46 p-2"
						v-for="t in topRegions"
						:key="t.id"
					>
						<div
							class="card flex-none h-full gradient-green flex flex-col justify-end items-center rounded-lg p-3 text-white"
						>
							<p class="b mb-auto" v-capitalizeWords="t.name"></p>
							<div class="w-full">
								<hr class="bg-yellow h-0.5 my-2 w-full block" />
								<p class="flex justify-between mb-2">
									Recibidos:
									<span
										class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
										>{{ t.received_votes }}</span
									>
								</p>
								<p class="flex justify-between">
									Enviados:<span
										class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
										>{{ t.emitted_votes }}</span
									>
								</p>
							</div>
						</div>
					</div>
				</div>
				<p class="blue text-lg pb-3">
					Regiones con <span class="b">menor</span> reconocimiento:
				</p>
				<div
					class="flex justify-center flex-wrap pb-6 stats-wrap md:justify-between"
				>
					<div
						class="p-2 h-46 w-60"
						v-for="t in lastRegions"
						:key="t.id"
					>
						<div
							class="card flex-none gradient-red flex flex-col justify-end items-center h-full rounded-lg p-3 text-white"
						>
							<p class="b mb-auto" v-capitalizeWords="t.name"></p>
							<div class="w-full">
								<hr class="bg-yellow h-0.5 my-2 w-full block" />
								<p class="flex justify-between mb-2">
									Recibidos:
									<span
										class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
										>{{ t.received_votes }}</span
									>
								</p>
								<p class="flex justify-between">
									Enviados:<span
										class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
										>{{ t.emitted_votes }}</span
									>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<p class="blue text-lg pb-3">
					Territorios con <span class="b">mayor</span> reconocimiento:
				</p>
				<div
					class="flex justify-center flex-wrap pb-6 stats-wrap md:justify-between"
				>
					<div
						class="w-60 h-46 p-2"
						v-for="t in topTerritories"
						:key="t.id"
					>
						<div
							class="card flex-none h-full gradient-green flex flex-col justify-end items-center rounded-lg p-3 text-white"
						>
							<p class="b mb-auto" v-capitalizeWords="t.name"></p>
							<div class="w-full">
								<hr class="bg-yellow h-0.5 my-2 w-full block" />
								<p class="flex justify-between mb-2">
									Recibidos:
									<span
										class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
										>{{ t.received_votes }}</span
									>
								</p>
								<p class="flex justify-between">
									Enviados:<span
										class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
										>{{ t.emitted_votes }}</span
									>
								</p>
							</div>
						</div>
					</div>
				</div>
				<p class="blue text-lg pb-3">
					Territorios con <span class="b">menor</span> reconocimiento:
				</p>
				<div
					class="flex justify-center flex-wrap pb-6 stats-wrap md:justify-between"
				>
					<div
						class="p-2 h-46 w-60"
						v-for="t in lastTerritories"
						:key="t.id"
					>
						<div
							class="card flex-none gradient-red flex flex-col justify-end items-center h-full rounded-lg p-3 text-white"
						>
							<p class="b mb-auto" v-capitalizeWords="t.name"></p>
							<div class="w-full">
								<hr class="bg-yellow h-0.5 my-2 w-full block" />
								<p class="flex justify-between mb-2">
									Recibidos:
									<span
										class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
										>{{ t.received_votes }}</span
									>
								</p>
								<p class="flex justify-between">
									Enviados:<span
										class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
										>{{ t.emitted_votes }}</span
									>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<p class="blue text-lg pb-3">
				Colaboradores con
				<span class="b">mayor</span> reconocimiento:
			</p>
			<div
				class="flex justify-center flex-wrap pb-6 stats-wrap md:justify-between"
			>
				<div
					class="w-60 h-60 p-2"
					v-for="t in topFiveUsers"
					:key="t.id"
				>
					<div
						class="card flex-none gradient-orange flex flex-col justify-end items-center h-full rounded-lg p-3 text-white"
					>
						<p
							class="w-full b block text-lg mb-auto"
							v-capitalizeWords="
								t.name
									? t.name
									: `${t.first_name} ${t.last_name}`
							"
						></p>
						<hr class="bg-yellow h-0.5 my-2 w-full block" />
						<p
							class="w-full block truncate b text-sm"
							v-capitalizeWords="t.work_position"
						></p>
						<p
							class="w-full block truncate text-sm"
							v-capitalizeWords="
								filters.territory_id.options[t.territory_id]
							"
						></p>
						<div class="w-full">
							<hr class="bg-yellow h-0.5 my-2 w-full block" />
							<p class="flex w-full justify-between mb-2">
								Recibidos:
								<span
									class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
									>{{ t.received_votes }}</span
								>
							</p>
							<p class="flex justify-between w-full">
								Enviados:<span
									class="inline-block flex-none b blue ml-2 w-1/2 h-8 rounded-md bg-white flex justify-center items-center"
									>{{ t.emitted_votes }}</span
								>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.stats-wrap {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	scroll-snap-points-x: repeat(300px);
	scroll-snap-type: mandatory;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		display: none;
	}

	> div {
		flex-shrink: 0;
		text-align: center;
	}
}
.card {
	&:not(:last-child) {
		margin-right: 1rem;
	}
}

/*
	Label the data
	*/

@media (max-width: 1023px) {
	$fields: 1 "No.", 2 "Nombre", 3 "Puesto", 4 "Votos";

	@each $i, $field in $fields {
		#no-more-tables td:nth-child(#{$i}):before {
			content: $field;
		}
	}
}
</style>
